import { create } from "@storybook/theming/create";
import canopyLogo from "../src/assets/canopy-logo-light.svg";

export default create({
  base: "light",
  brandTitle: "Canopy Aura",
  brandUrl: "/",
  brandImage: canopyLogo,

  colorPrimary: "#3152F5",
  colorSecondary: "#3152F5",
});
